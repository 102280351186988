<section class="apt-form my-4">
    <form [formGroup]="reportForm">
        <div class="container-fluid mb-3">
            <div class="row apt-dashboard-card">
                <div class="col-lg-12 apt-dashboard-card-inner">
                    <div class="card1 p-0 ">
                        <div class="filter-sec">
                            <div class="toggle-sec">
                                <!-- [ngClass]="{' is-off': reportForm.get('uniqueCountOn')?.value}" -->
                                    <div class="switch is-on">
                                        <div class="custom-control custom-switch toggle-sec">
                                            <input type="checkbox" [checked]="isUniqueCountOn" (change)="changeUniqueMode($event)"  class="custom-control-input" id="customSwitch1" formControlName="uniqueCountOn">
                                            <label class="custom-control-label switch__label" for="customSwitch1">Unique count</label>
                                        </div>
                                    </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="apt-type bg-white">
                                    <div class="form-group mb-0">
                                        <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                            <label class="btn btn-outline-info active mr-2" for="complex">
                                                <input type="radio" id="complex" name="report" checked
                                                    (change)="onFilterSelect('complex')"><i class="fe fe-home mr-2"></i> Complex
                                            </label>
                                            <label class="btn btn-outline-info" for="building">
                                                <input type="radio" id="building" name="report"
                                                    (change)="onFilterSelect('building')"> <i
                                                    class="ti ti-building-skyscraper mr-1"></i> Building
                                            </label>
                                        </div>
                                    </div>
                                </div>
                              
                                <div class="form-group mb-0 ml-2 apt-filter-select apt-custom-form">
                                    <ng-select 
                                        placeholder="Select Type" 
                                        class="custom-ng-select" 
                                        [multiple]="false"
                                        [dropdownPosition]="'bottom'" 
                                        [items]="reportType" 
                                        formControlName="change_info"
                                        (change)="onFilterReport()" 
                                        bindLabel='label' 
                                        bindValue="value"
                                        >
                                        <ng-template ng-option-tmp let-item="item">
                                            {{item.label}}
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="form-group mb-0 ml-2 apt-filter-select apt-custom-form ">
                                    <ng-select 
                                        placeholder="Select Status" 
                                        class="custom-ng-select" 
                                        [multiple]="true"
                                        [dropdownPosition]="'bottom'" 
                                        [items]="reportStatus" 
                                        [closeOnSelect]="false"
                                        formControlName="approved_status" 
                                        (clear)="onFilterReport()"
                                        (focusout)="onFilterReport()" 
                                        bindLabel='label' 
                                        bindValue="value"
                                        >
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let status of items | slice:0:1">
                                                <span class="ng-value-label">
                                                    {{status.label}}
                                                </span>
                                                <span class="ng-value-icon right" (click)="clear(status)"
                                                    aria-hidden="true">×</span>

                                            </div>
                                            <div class="ng-value more" *ngIf="items.length > 1">
                                                <span class="ng-value-label more">{{items.length - 1}} more...</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="apt-calender ml-3">
                                    <div class="form-group mb-0">
                                        <div class="dropdown calander-filter ">
                                            <button type="button" class="btn dropdown-toggle w-100" data-toggle="dropdown">
                                                <span class="mr-1"><i class="ti ti-calendar"></i></span>
                                                {{defaultSelectedVal}}<span class="calander-down-arrow ml-1"><i class="lnr lnr-chevron-down"></i></span>
                                            </button>
                                            <div class="dropdown-menu" style="cursor: pointer;">
                                                <a class="dropdown-item" [ngClass]="{'active': frequency == 'TODAY'}" (click)="onFilterSelect('today')"
                                                    name="today">Today</a>
                                
                                                <a class="dropdown-item" [ngClass]="{'active': frequency == 'YESTERDAY'}"
                                                    (click)="onFilterSelect('yesterday')" name="yesterday">Yesterday</a>
                                
                                                <a class="dropdown-item" [ngClass]="{'active': frequency == 'LAST7DAYS'}"
                                                    (click)="onFilterSelect('last7days')" name="last7days">Last 7 Days</a>
                                
                                                <!--  <a class="dropdown-item" (click)="onFilterSelect('last30days')"
                                                                            name="last30days">Last 30 Days</a>
                                        
                                                                    <a class="dropdown-item" (click)="onFilterSelect('thismonth')"
                                                                            name="thismonth">This Month</a> -->
                                
                                                <a class="dropdown-item" name="Current Month" [ngClass]="{'active': frequency == 'CURRENTMONTH'}"
                                                    (click)="onFilterSelect('thismonth')">Current Month</a>
                                                <div class="col-12  p-0 form-group mb-2 formgroup-datepicker">
                                                    <a class="dropdown-item" [ngClass]="{'active': frequency == 'DATERANGE'}"
                                                        (click)="toggleDateRange(); $event.stopPropagation()">Custom
                                                        Range</a>
                                                    <div class="p-2">
                                                        <input type="text" #datepickerInput placeholder="Select Date Range"
                                                            class="form-control form-control-sm datepicker-textbox" placement="bottom right"
                                                            (bsValueChange)="onFilterSelect('daterange',$event)"
                                                            [bsConfig]="{ containerClass: 'theme-green custom-datepicker2', isAnimated: true , showWeekNumbers : false,  maxDate: today }"
                                                            bsDaterangepicker>
                                                    </div>
                                                </div>
                                
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-3 apt-filter apt-filter-backdrop"
                                    [ngClass]="{'apt-backdrop-close': filterOverlay}" data-toggle="collapse"
                                    href="#filter-1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                    <div class="apt-search-overlay"></div>
                                    <div class="form-group mb-0">
                                        <div class="dropdown"
                                            [ngClass]="{'notify': filterHasValue, 'apt-hide': !filterOverlay}">
                                            <button type="button" class="btn btn-primary  dropdown-toggle"
                                                data-toggle="dropdown" (click)="checkFilterValues()">
                                                <span><i class="ti ti-filter"></i></span>
                                            </button>
                                            <div class="collapse dropdown-menu apt-show dropdown-menu-right p-2"
                                                id="#filter-1">
                                                <div class="filter-sec form-row align-items-start">
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label> State</label>
                                                                    <ng-select 
                                                                        placeholder="Select State" 
                                                                        [multiple]="false"
                                                                        [loading]="isloading['state']" 
                                                                        (change)="getCounties();getCities();checkFilterValues()"
                                                                        (clear)="getCounties();getCities()" 
                                                                        [items]="states"
                                                                        [dropdownPosition]="'bottom'"
                                                                        formControlName="state"
                                                                        bindLabel='state_full_name' 
                                                                        bindValue="state"
                                                                        >
                                                                        <ng-template ng-option-tmp let-item="item">
                                                                            {{item.state_full_name}}
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label for="County" class="form-label">County</label>
                                                                    <ng-select 
                                                                        placeholder="Select county"
                                                                        [loading]="isloading['counties']"
                                                                        [searchable]="true" 
                                                                        [virtualScroll]="true"
                                                                        (remove)="getCities()" 
                                                                        (clear)="getCities()"
                                                                        [closeOnSelect]="false" 
                                                                        (change)="getCities()"
                                                                        [multiple]="true" 
                                                                        formControlName="county"
                                                                        [dropdownPosition]="'bottom'"
                                                                        [items]="countySearchList.length > 0 ? countySearchList: countyList"
                                                                        bindLabel="county" 
                                                                        bindValue="county"
                                                                        >
                                                                        <ng-template ng-multi-label-tmp let-items="items"
                                                                            let-clear="clear">
                                                                            <div class="ng-value"
                                                                                *ngFor="let counties of items | slice:0:1">
                                                                                <span class="ng-value-label">
                                                                                    {{counties.county}}
                                                                                </span>
                                                                                <span class="ng-value-icon right"
                                                                                    (click)="clear(counties)"
                                                                                    aria-hidden="true">×</span>
                                                                            </div>
                                                                            <div class="ng-value more"
                                                                                *ngIf="items.length > 1">
                                                                                <span
                                                                                    class="ng-value-label more">{{items.length
                                                                                    - 1}} more...</span>
                                                                            </div>

                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label> City</label>
                                                                    <ng-select 
                                                                        placeholder="Select City" 
                                                                        [multiple]="true"
                                                                        [loading]="this.isloading['cities']"
                                                                        formControlName="city"
                                                                        (focusout)="getZips();checkFilterValues()"
                                                                        (clear)="getZips()"
                                                                        [items]="searchCityList.length > 0 ? searchCityList : cities"
                                                                        [dropdownPosition]="'bottom'"
                                                                        [closeOnSelect]="false" 
                                                                        bindLabel="city_label"
                                                                        bindValue="city_control"
                                                                        >
                                                                        <ng-template ng-multi-label-tmp let-items="items"
                                                                            let-clear="clear">
                                                                            <div class="ng-value"
                                                                                *ngFor="let cities of items | slice:0:1">
                                                                                <span class="ng-value-label">
                                                                                    {{cities.city_label}}
                                                                                </span>
                                                                                <span class="ng-value-icon right"
                                                                                    (click)="clear(cities)"
                                                                                    aria-hidden="true">×</span>
                                                                            </div>
                                                                            <div class="ng-value more"
                                                                                *ngIf="items.length > 1">
                                                                                <span
                                                                                    class="ng-value-label more">{{items.length
                                                                                    - 1}} more...</span>
                                                                            </div>

                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label> Zip</label>
                                                                    <ng-select 
                                                                        placeholder="Select Zip" 
                                                                        [loading]="isloading['zips']" 
                                                                        [multiple]="true" 
                                                                        formControlName="zip" 
                                                                        [items]="zips"
                                                                        (focusout)="onZipSelection();checkFilterValues()" 
                                                                        [dropdownPosition]="'bottom'" 
                                                                        [closeOnSelect]="false"
                                                                        bindLabel='zip' 
                                                                        bindValue="zip"
                                                                        >
                                                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                                            <div class="ng-value" *ngFor="let zips of items | slice:0:2">
                                                                                <span class="ng-value-label">
                                                                                    {{zips.zip}}
                                                                                </span>
                                                                                <span class="ng-value-icon right" (click)="clear(zips)" aria-hidden="true">×</span>
                                                                            </div>
                                                                            <div class="ng-value more" *ngIf="items.length > 2">
                                                                                <span class="ng-value-label more">{{items.length
                                                                                    - 2}} more...</span>
                                                                            </div>
                                                                    
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-12" *ngIf="this.userDetails.role_id == 1">
                                                                <div class="form-group apt-filter-select">
                                                                    <label>Data Operator</label>
                                                                    <ng-select 
                                                                        placeholder="Select Data Operators"
                                                                        [multiple]="false"
                                                                        formControlName="selectedDataOperator"
                                                                        [dropdownPosition]="'bottom'"
                                                                        (change)="checkFilterValues();getUserbyDataOperator()"
                                                                        [items]="dataOperators" 
                                                                        bindLabel='fullname'
                                                                        >
                                                                        <ng-template ng-option-tmp let-item="item">
                                                                            {{item.fullname}} <span
                                                                                class="d-email">({{item.email}})</span>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" *ngIf="userDetails.role_id != 3">
                                                                <div class="form-group">
                                                                    <label>Select Users</label>
                                                                    <ng-select 
                                                                        placeholder="Select Users" 
                                                                        [multiple]="false"
                                                                        formControlName="selectedUser"
                                                                        (change)="checkFilterValues()" 
                                                                        [items]="users"
                                                                        [dropdownPosition]="'bottom'" 
                                                                        bindLabel='fullname'
                                                                        >
                                                                        <ng-template ng-option-tmp let-item="item">
                                                                            {{item.fullname}} <span
                                                                                class="d-email">({{item.email}})</span>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="btn-sec d-flex justify-content-center">
                                                            <button type="button" class="btn btn-default mr-3"
                                                                (click)="filterOverlay = false">
                                                                <i class="fe fe-x mr-2"></i> Cancel
                                                            </button>
                                                            <button type="button" class="btn btn-primary "
                                                                (click)="getFilteredData()">
                                                                <i class="fe fe-check mr-2"></i> Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mt-4">
                    <!-- [ngStyle]="{ 'display': reportForm.get('uniqueCountOn')?.value ? 'none' : 'block' }" -->
                    <div [ngStyle]="{ 'display': reportForm.get('uniqueCountOn')?.value ? 'none' : 'block' }" >
                        <section>
                            <div class="container-fluid mb-3">
                                <div class="row apt-dashboard-card mb-3">
                                    <div class="col-lg-12 apt-bar-sec apt-dashboard-card-inner p-0">
                                        <div class="card position-relative">
                                            <app-div-loader *ngIf="chartLoader"></app-div-loader>
                                            <div class="card-header">
                                                <h3>{{ fieldName == "complex" ? 'Complex Approval Status Overview' : 'Building Approval Status Overview'}}</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="apt-bar-chart" *ngIf="!noGraphData">
                                                    <div [chart]="columnChart"></div>
                                                </div>
                                                <ng-container *ngIf="noGraphData">
                                                    <div class="no-data-block">
                                                        <div class="no-data-block-inner d-flex flex-column">
                                                            <div class="no-data-block-img mt-auto">
                                                                <img [src]="noRecordFound" />
                                                            </div>
                                                            <p class="mb-auto">No Data Found !</p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                
                            <div class="container-fluid">
                                <div class="row apt-dashboard-card mb-3">
                
                                    <div class="col-lg-12 apt-table-sec apt-dashboard-card-inner  p-0">
                                        <app-div-loader *ngIf="changesLoader"></app-div-loader>
                                        <div class="card position-relative">
                
                                            <div class="card-header  d-flex w-100 justify-content-between align-items-center">
                                                <h3 class="mb-0">{{ fieldName == "complex" ? 'Recent Modifications in Complex Data' : 'Recent Modifications in Building Data'}}</h3>
                                                <div class="apt-filter-right d-flex">
                                                    <div class="card-head-rght d-flex">
                                                        <div class="input-group">
                                                            <!-- <input type="text" class="form-control" placeholder="Search" aria-label=""
                                                    aria-describedby="basic-addon1" [(ngModel)]="searchUsers" (ngModelChange)="onSearchInputChange()"> -->
                                                            <input pInputText #searchVal class="form-control" type="text"
                                                                (input)="applyFilterGlobal($event, 'contains')"
                                                                placeholder="Search" />
                                                            <div class="input-group-append">
                                                                <button class="btn btn-primary  apt-srch-icon" type="button"><i
                                                                        class="fe fe-search"></i></button>
                                                            </div>
                                                        </div>
                
                                                    </div>
                                                </div>
                                            </div>
                
                
                                            <div class="card-body apt-card-inner p-0 apt-report">
                                                <!--   <p-table [value]="recentlyAddedList" [scrollable]="true" class="apt-complex-table"
                                    [scrollHeight]="'calc(100vh - 232px)'" #dt [rows]="rows"  [paginator]="true"  [globalFilterFields]="['complex_name','address','state', 'city', 'zip','approved_status','changed_by']" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records" [rowsPerPageOptions]="[15,25,50]"> -->
                                                <p-table [scrollHeight]="'calc(100vh - 232px)'" [scrollable]="true" #dt
                                                    [value]="recentlyAddedList" [paginator]="false" [rows]="rows"
                                                    [showCurrentPageReport]="true" [tableStyle]="{'min-width': '60rem'}"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                                    [rowsPerPageOptions]="[15,25,50]"
                                                    [globalFilterFields]="['complex_name','address','state', 'city', 'zip','reason','change_info','approved_status','requested_on','requested_by','approved_on','changed_by']">
                
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn="complex_name">Complex<p-sortIcon
                                                                    field="complex_name"></p-sortIcon></th>
                                                            <th pSortableColumn="address">Building <p-sortIcon
                                                                    field="address"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="state">State <p-sortIcon
                                                                    field="state"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="city">City <p-sortIcon
                                                                    field="city"></p-sortIcon></th>
                                                            <th pSortableColumn="zip">Zip <p-sortIcon field="zip"></p-sortIcon>
                                                            </th>
                                                            <th *ngIf="recentlyAddedList && recentlyAddedList[0]?.reason" pSortableColumn="reason" title="Change Info">Change Info
                                                                <p-sortIcon field="reason"></p-sortIcon></th>
                                                            <th *ngIf="recentlyAddedList && recentlyAddedList[0]?.change_info" pSortableColumn="change_info" title="Change Info">Change Info
                                                                <p-sortIcon field="change_info"></p-sortIcon></th>
                                                            <th pSortableColumn="approved_status" title="Approved Status">
                                                                Approved
                                                                Status <p-sortIcon field="approved_status"></p-sortIcon></th>
                                                            <th pSortableColumn="requested_on" title="Requested on">Requested on
                                                                <p-sortIcon field="requested_on"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="requested_by" title="Requested by">Requested by
                                                                <p-sortIcon field="requested_by"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="approved_on" title="Approved On">Approved On
                                                                <p-sortIcon field="approved_on"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="changed_by" title="Approved by">Approved by
                                                                <p-sortIcon field="changed_by"></p-sortIcon></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-newComplex>
                                                        <tr>
                                                            <td title="{{newComplex.complex_name || newComplex.manual_complex_name}}">{{newComplex.complex_name || newComplex.manual_complex_name}}
                                                            </td>
                                                            <td title="{{newComplex.address}}">{{newComplex.address}}</td>
                                                            <td>{{newComplex.state}}</td>
                                                            <td>{{newComplex.city}}</td>
                                                            <td>{{newComplex.zip}}</td>
                                                            <td title="{{newComplex?.reason | statusName}}"
                                                                *ngIf="newComplex?.reason">
                                                                {{newComplex?.reason | statusName}}</td>
                                                            <td title="{{newComplex?.change_info | statusName}}"
                                                                *ngIf="newComplex?.change_info">{{newComplex?.change_info |
                                                                statusName}}
                                                            </td>
                                                            <td class="{{newComplex.approved_status}}">
                                                                {{newComplex.approved_status |
                                                                titlecase}}</td>
                                                            <td>{{newComplex.requested_on}}</td>
                                                            <td>{{newComplex.requested_by_name | titlecase}}</td>
                                                            <td>{{newComplex.approved_on}}</td>
                                                            <td>{{(newComplex.approved_by !='null') ?
                                                                newComplex.approved_by_name :
                                                                '-'}}</td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="12" style="text-align:center" class="no-data-found">
                                                                <img [src]="noDataImage" width="50" alt=""><br>
                                                                <span>No Data Found...</span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p-paginator [appendTo]="'body'"
                                                    *ngIf="recentlyAddedList && recentlyAddedList.length > 0"
                                                    [showCurrentPageReport]="true"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                                    (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
                                                    [totalRecords]="totalCount"></p-paginator>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                            </div>
                        </section>
                    </div>
                    <!-- [ngStyle]="{ 'display': reportForm.get('uniqueCountOn')?.value ? 'block' : 'none' }" -->
                    <div [ngStyle]="{ 'display': reportForm.get('uniqueCountOn')?.value ? 'block' : 'none' }" >
                        <section>
                            <div class="container-fluid mb-3">
                                <div class="row apt-dashboard-card mb-3">
                                    <div class="col-lg-12 apt-bar-sec apt-dashboard-card-inner p-0">
                                        <div class="card position-relative">
                                            <app-div-loader *ngIf="chartLoader"></app-div-loader>
                                            <div class="card-header">
                                                <h3>{{ fieldName == "complex" ? 'Complex Unique Status Overview' : 'Building Unique Status Overview'}}</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="apt-bar-chart" *ngIf="!noGraphData">
                                                    <div [chart]="columnUniqueChart"></div>
                                                </div>
                                                <ng-container *ngIf="noGraphData">
                                                    <div class="no-data-block">
                                                        <div class="no-data-block-inner d-flex flex-column">
                                                            <div class="no-data-block-img mt-auto">
                                                                <img [src]="noRecordFound" />
                                                            </div>
                                                            <p class="mb-auto">No Data Found !</p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                
                            <div class="container-fluid">
                                <div class="row apt-dashboard-card mb-3">
                
                                    <div class="col-lg-12 apt-table-sec apt-dashboard-card-inner  p-0">
                                        <app-div-loader *ngIf="changesLoader"></app-div-loader>
                                        <div class="card position-relative">
                
                                            <div class="card-header  d-flex w-100 justify-content-between align-items-center">
                                                <h3 class="mb-0">{{ fieldName == "complex" ? 'Complex Details' : 'Building Details'}}</h3>
                                                <div class="apt-filter-right d-flex">
                                                    <div class="card-head-rght d-flex">
                                                        <div class="input-group">
                                                            <!-- <input type="text" class="form-control" placeholder="Search" aria-label=""
                                                    aria-describedby="basic-addon1" [(ngModel)]="searchUsers" (ngModelChange)="onSearchInputChange()"> -->
                                                            <input pInputText #searchVal class="form-control" type="text"
                                                                (input)="applyFilterGlobal($event, 'contains')"
                                                                placeholder="Search" />
                                                            <div class="input-group-append">
                                                                <button class="btn btn-primary  apt-srch-icon" type="button"><i
                                                                        class="fe fe-search"></i></button>
                                                            </div>
                                                        </div>
                
                                                    </div>
                                                </div>
                                            </div>
                
                
                                            <div class="card-body apt-card-inner p-0 apt-report">
                                                <!--   <p-table [value]="recentlyAddedList" [scrollable]="true" class="apt-complex-table"
                                    [scrollHeight]="'calc(100vh - 232px)'" #dt [rows]="rows"  [paginator]="true"  [globalFilterFields]="['complex_name','address','state', 'city', 'zip','approved_status','changed_by']" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records" [rowsPerPageOptions]="[15,25,50]"> -->
                                                <p-table [scrollHeight]="'calc(100vh - 232px)'" [scrollable]="true" #dt
                                                    [value]="uniqueDetailList" [paginator]="false" [rows]="rows"
                                                    [showCurrentPageReport]="true" [tableStyle]="{'min-width': '60rem'}"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                                    [rowsPerPageOptions]="[15,25,50]"
                                                    [globalFilterFields]="['complex_name','address','state', 'city', 'zip','reason','change_info','approved_status','requested_on','requested_by','approved_on','changed_by']">
                
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn="complex_name">Complex <p-sortIcon
                                                                    field="complex_name"></p-sortIcon></th>
                                                            <th pSortableColumn="address">Building <p-sortIcon
                                                                    field="address"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="state">State <p-sortIcon
                                                                    field="state"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="city">City <p-sortIcon
                                                                    field="city"></p-sortIcon></th>
                                                            <th pSortableColumn="zip">Zip <p-sortIcon field="zip"></p-sortIcon>
                                                            </th>
                                                            <th *ngIf="uniqueDetailList && uniqueDetailList[0]?.reason" pSortableColumn="reason" title="Change Info">Change Info
                                                                <p-sortIcon field="reason"></p-sortIcon></th>
                                                            <th *ngIf="uniqueDetailList && uniqueDetailList[0]?.change_info" pSortableColumn="change_info" title="Change Info">Change Info
                                                                <p-sortIcon field="change_info"></p-sortIcon></th>
                                                            <th pSortableColumn="approved_status" title="Approved Status">
                                                                Approved
                                                                Status <p-sortIcon field="approved_status"></p-sortIcon></th>
                                                            <th pSortableColumn="requested_on" title="Requested on">Requested on
                                                                <p-sortIcon field="requested_on"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="requested_by" title="Requested by">Requested by
                                                                <p-sortIcon field="requested_by"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="approved_on" title="Approved On">Approved On
                                                                <p-sortIcon field="approved_on"></p-sortIcon>
                                                            </th>
                                                            <th pSortableColumn="changed_by" title="Approved by">Approved by
                                                                <p-sortIcon field="changed_by"></p-sortIcon></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-newComplex>
                                                        <tr>
                                                            <td title="{{ newComplex.manual_complex_name ?? newComplex.complex_name }}">{{ newComplex.manual_complex_name ?? newComplex.complex_name }}
                                                            </td>
                                                            <td title="{{newComplex.address}}">{{newComplex.address}}</td>
                                                            <td>{{newComplex.state}}</td>
                                                            <td>{{newComplex.city}}</td>
                                                            <td>{{newComplex.zip}}</td>
                                                            <td title="{{newComplex?.reason | statusName}}"
                                                                *ngIf="newComplex?.reason">
                                                                {{newComplex?.reason | statusName}}</td>
                                                            <td title="{{newComplex?.change_info | statusName}}"
                                                                *ngIf="newComplex?.change_info">{{newComplex?.change_info |
                                                                statusName}}
                                                            </td>
                                                            <td class="{{newComplex.approved_status}}">
                                                                {{newComplex.approved_status |
                                                                titlecase}}</td>
                                                            <td>{{newComplex.requested_on}}</td>
                                                            <td>{{newComplex.requested_by_name | titlecase}}</td>
                                                            <td>{{newComplex.approved_on}}</td>
                                                            <td>{{(newComplex.approved_by !='null') ?
                                                                newComplex.approved_by_name :
                                                                '-'}}</td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="12" style="text-align:center" class="no-data-found">
                                                                <img [src]="noDataImage" width="50" alt=""><br>
                                                                <span>No Data Found...</span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p-paginator [appendTo]="'body'"
                                                    *ngIf="uniqueDetailList && uniqueDetailList.length > 0"
                                                    [showCurrentPageReport]="true"
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                                    (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
                                                    [totalRecords]="totalCount"></p-paginator>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                            </div>
                        </section>
                    </div>                
                </div>
            </div>
        </div>
    </form>
</section>
 