import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, catchError, debounceTime, distinctUntilChanged, of, retry, switchMap, take, Subscription } from 'rxjs';
import { unitRangeList, API_CONFIG, BuildingCountRange, ApartmentModule, mapSettings, markerChangeRequest, initialPayload } from '../../../_config';
import { defaultStatesPayload, search, searchDefaultPayload, searchDefaultValue, } from '../../../apartment/apartment.model';
import { ApiService } from '../../../_core/services';
import { ApartmentService } from '../../../apartment/apartment.service';
import { GeoService } from 'src/app/_core/services/geo.service';
import _ from 'lodash';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
 
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  searchForm: FormGroup = {} as FormGroup;
  isCollapsed = true;
  enableOverlay = false;
  aptFilters: any = {};
  unitRangeList = unitRangeList;
  searchTerm$ = new Subject<string>();
  searchResults: any[] = [];
  currentZip: any;
  complexNames: any;
  stateList: any;
  countyList: any;
  cityList: any;
  zipList: any;
  isloading: any = {};
  msoList: any = [];
  textLoader = false;
  enableTextSearchOverlay = false;
  buildingCountList = BuildingCountRange;
  @Output() searchInputData = new EventEmitter<any>();
  previousValue: any;
  getChangeRequest = markerChangeRequest;
  tags = [];
  aptListFrombuilding: boolean = false;;

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent): void {
    if (this.enableOverlay == false && this.enableTextSearchOverlay == false) {
      const currentValue = this.searchForm.value;
      const oldValue = this.previousValue;

      if (!_.isEqual(currentValue, oldValue)) {
        this.doSearch();
      }
    }

  }

  constructor(
    private formbuilder: FormBuilder,
    private apartmentService: ApartmentService,
    private apiService: ApiService,
    private router: Router,
    private geoService: GeoService
  ) {
    this.apartmentService.searchFilters.subscribe(
      (vl: any) => (this.aptFilters = vl)
    );
    this.apartmentService.tags$.subscribe((res) => {
      this.tags = res;
    });

    // this.subscription = 



  }

  get f() {
    return this.searchForm.controls;
  }

  // On GEO Clear
  onClear(type: string) {
    switch (type) {
      case 'state':
        this.searchForm.get('county')?.disable();
        this.searchForm.get('city')?.disable();
        this.searchForm.get('zip')?.disable();
        this.searchForm.patchValue({ state: [], county: [], city: [], zip: [] });
        break;
      case 'county':
        this.searchForm.get('zip')?.disable();
        this.searchForm.patchValue({ county: [], city: [], zip: [] });
        break;
      case 'city':
        this.searchForm.get('zip')?.disable();
        this.searchForm.patchValue({ city: [], zip: [] });
        break;
    }
  }

  onRemove(type: string, event: any) {
    const removedElement = event ? event.value : '';
    const county = this.searchForm.get('county')?.value;
    const city = this.searchForm.get('city')?.value;
    const zip = this.searchForm.get('zip')?.value;

    if (type == 'state') {
      const filteredCounty = county ? county.filter((item: any) => item.state !== removedElement.state) : [];
      const filteredCity = city ? city.filter((item: any) => item.state !== removedElement.state) : [];
      const filteredZip = zip ? zip.filter((item: any) => item.state !== removedElement.state) : [];
      this.searchForm.patchValue({
        county: filteredCounty,
        city: filteredCity,
        zip: filteredZip
      });
      // this.getLoaded('county');
    } else if (type == 'county') {
      const filteredCity = city ? city.filter((item: any) => item.county !== removedElement.county) : [];
      const filteredZip = zip ? zip.filter((item: any) => item.county !== removedElement.county) : [];
      this.searchForm.patchValue({
        city: filteredCity,
        zip: filteredZip
      });

      // this.getLoaded('city');
    } else if (type == 'city') {
      const filteredZip = zip ? zip.filter((item: any) => item.city != removedElement.city) : zip;
      this.searchForm.patchValue({
        zip: filteredZip
      });
    }
    if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length == 0) {
      this.searchForm.get('zip')?.disable();
    }
  }

  checkDependent(type: string) {
    if (type == 'county') {
      // dependent validation on county 
      const counties = this.searchForm.get('county')?.value
      const cities = this.searchForm.get('city')?.value
      const zips = this.searchForm.get('zip')?.value

      const matchingCities = cities && cities.filter((cityEntry: any) => {
        if (counties && counties.length) return counties.some((countyEntry: any) => countyEntry.county === cityEntry.county);
        else return cityEntry
      });

      const matchingZips = zips && zips.filter((zipEntry: any) => {
        return matchingCities.some((cityEntry: any) => cityEntry.city === zipEntry.city);
      });
      this.searchForm.patchValue({
        city: matchingCities,
        zip: matchingZips
      })
      if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length == 0) {
        this.searchForm.get('zip')?.disable();
      }
    } else if (type == 'city') {
      // dependent validation on city 
      const cities = this.searchForm.get('city')?.value
      const zips = this.searchForm.get('zip')?.value

      const matchingZips = zips && zips.filter((zipEntry: any) => {
        return cities.some((cityEntry: any) => cityEntry.city === zipEntry.city);
      });
      this.searchForm.patchValue({
        zip: matchingZips
      });
    }
  }

  getLoaded(type: string) {
    const payload: any = searchDefaultPayload;
    delete payload.search;
    this.searchForm.get(type)?.enable();
    if (type == 'county') {
      if (this.searchForm.get('state')?.value != null || typeof this.searchForm.get('state')?.value == 'string') {
        this.searchForm.get('city')?.enable();
      } else {
        this.searchForm.get('city')?.disable();
        this.searchForm.get('zip')?.disable();
      }

      this.onClear(type);
      payload.state = this.searchForm.get('state')?.value ? [this.searchForm.get('state')?.value] : [];
      payload.sort_field = [
        "state",
        "county",
      ]
      if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length == 0) {
        this.searchForm.get('zip')?.disable();
      }
      delete payload.county
      delete payload.city
      if (this.searchForm.get('state')?.value != null) {
        this.GetListAPI(payload, type);
        payload.sort_field = [
          "state",
          "city",
        ]
        this.GetListAPI(payload, 'city');
      }

    } else if (type == 'city') {

      if (this.searchForm.get('city')?.value?.length != 0) {
        this.searchForm.get('zip')?.enable();
      } else {
        this.searchForm.get('zip')?.disable();
      }

      payload.state = this.searchForm.get('state')?.value ? [this.searchForm.get('state')?.value] : [];
      payload.county = this.searchForm.get('county')?.value ? this.searchForm.get('county')?.value.map((val: any) => val.county) : [];
      payload.sort_field = [
        "state",
        "city",
        "county",
      ]
      delete payload.city
      this.GetListAPI(payload, type);
      if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length == 0) {
        this.searchForm.get('zip')?.disable();
      }
      // this.getLoaded('zip');
    } else if (type == 'zip') {

      payload.state = this.searchForm.get('state')?.value ? [this.searchForm.get('state')?.value] : [];
      payload.county = this.searchForm.get('county')?.value ? this.searchForm.get('county')?.value.map((val: any) => val.county) : [];
      payload.city = this.searchForm.get('city')?.value ? this.searchForm.get('city')?.value.map((val: any) => {
        return `${val.state}_${val.city}`
      }) : []
      delete payload.zip
      payload.sort_field = [
        "state",
        "city",
        "county",
        "zip"
      ]
      if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length != 0) {
        this.GetListAPI(payload, type);
      }

    }
  }

  GetList(event: any, type: string) {
    const input: any = searchDefaultPayload;
    input.search = event.term ? event.term : '';
    if (type == 'county') {
      const stateValue = this.searchForm.get('state')?.value;
      input.state = stateValue && typeof stateValue == 'string' ? [this.searchForm.get('state')?.value] : [];
      input.sort_field = [
        "state",
        "county",
        "city"
      ]
      delete input.county;
      delete input.city;
      this.GetListAPI(input, type);
    } else if (type == 'city') {
      input.state = this.searchForm.get('state')?.value ? [this.searchForm.get('state')?.value] : [];
      input.county = this.searchForm.get('county')?.value ? this.searchForm.get('county')?.value.map((val: any) => val.county) : [];
      input.sort_field = [
        "state",
        "county",
        "city"
      ]
      delete input.city;
      this.GetListAPI(input, type);
    } else if (type == 'zip') {
      const payload: any = searchDefaultPayload;
      payload.state = this.searchForm.get('state')?.value ? [this.searchForm.get('state')?.value] : [];
      payload.county = this.searchForm.get('county')?.value ? this.searchForm.get('county')?.value.map((val: any) => val.county) : [];
      payload.city = this.searchForm.get('city')?.value ? this.searchForm.get('city')?.value.map((val: any) => {
        return `${val.state}_${val.city}`
      }) : []
      payload.sort_field = [
        "state",
        "city",
        "county",
        "zip"
      ]
      delete input.zip;
      this.GetListAPI(payload, type);
    }
  }

  GetListAPI(payload: any, type: string) {
    const typeToUrlMap: any = {
      name: API_CONFIG.nameSearch,
      state: API_CONFIG.getStates,
      county: API_CONFIG.getCounty,
      city: API_CONFIG.getCities,
      zip: API_CONFIG.getZips,
    };
    const url = typeToUrlMap[type];

    this.isloading[type] = true;
    this.apiService.post(url, payload).subscribe((data: any) => {
      this.isloading[type] = false;
      if (type == 'name') {
        this.complexNames = data.complexes;
      } else if (type == 'state') {
        this.stateList = data.states;
        this.geoService.stateList.next(this.stateList)
      } else if (type == 'county') {
        this.countyList = data.counties.filter((city: any) => city.city != '');
        this.countyList.map((val: any) => {
          val['county_label'] = `${val.county} ${val.city ? `(${val.city})` : ''}`;
        });
      } else if (type == 'city') {
        this.cityList = data.cities.filter((city: any) => city.city != '');
        this.cityList.map((val: any) => {
          val['city_label'] = `${val.city} (${val.state_full_name})`
          val['city_control'] = `${val.state}_${val.city}`
        });
      } else if (type == 'zip') {
        const convertedData = data.zips.map((item: any) => {
          return { ...item, zip: item.zip.toString() };
        });
        this.zipList = convertedData;
      }
    });
  }

  formInit() {
    this.searchForm = this.formbuilder.group({
      complex_name: [''],
      state: [null],
      county: [null],
      city: [[{ value: '', disabled: true }]],
      zip: [[{ value: '', disabled: true }]],
      // mapped_status: [this.aptFilters.filters.mappedStatus],
      unit_range: [null],
      search_type: [this.aptFilters.filters.searchType],
      mso: [null],
      tags: [null],
      building_count: [null]
    });
  }

  checkValue(value: any) {
    return value == '' ? null : value;
  }

  doSearch() {
    this.apartmentService.closeModel.next(true)
    this.enableOverlay = false;
    this.enableTextSearchOverlay = false;
    if (!this.isCollapsed) {
      this.isCollapsed = true;
    }
    // close text search result window
    this.searchResults = [];

    const Input: any = {
      ...searchDefaultValue,
      filters: {
        // ...searchDefaultValue.filters,
        zip: this.searchForm.get('zip')?.value?.map((zip: any) => zip.zip.toString()),
        state: this.searchForm.get('state')?.value ? this.searchForm.get('state')?.value : [],
        complex_name: this.searchForm.get('complex_name')?.value || '',
        city: this.searchForm.get('city')?.value ? this.searchForm.get('city')?.value.map((val: any) => {
          return `${val.state}_${val.city}`
        }) : [],
        polygon: [],
        county: this.searchForm.get('county')?.value ? this.searchForm.get('county')?.value?.map((val: any) => val.county) : [],
        mso: this.searchForm.get('mso')?.value || [],
        tags: this.searchForm.get('tags')?.value?.map((tag: any) => tag.id) || [],
        approved_status: this.aptFilters.filters['approved_status'] ? this.aptFilters.filters['approved_status'] : [],
        verification_status: this.aptFilters.filters['verification_status'] ? this.aptFilters.filters['verification_status'] : []
      },
      sort: {
        sort_field: this.aptFilters.sort.sort_field,
        sort_order: this.aptFilters.sort.sort_order
      },
      size: this.aptFilters.size
    };
    if (this.searchForm.get('state')?.value && this.searchForm.get('state')?.value.length) {
      Input.filters.state = [this.searchForm.get('state')?.value]
    }

    if (this.f['unit_range'].value && this.f['unit_range'].value.hasOwnProperty('to')) {
      Input.filters['unit_range'] = {
        unit_count_from: this.f['unit_range']?.value.from,
        unit_count_to: this.f['unit_range']?.value.to,
      }
    } else if (this.f['unit_range'].value) {
      Input.filters['unit_range'] = {
        unit_count_from: this.f['unit_range']?.value.from,
      };
    }

    if (this.f['building_count'].value && this.f['building_count'].value.hasOwnProperty('to')) {
      Input.filters['building_range'] = {
        building_count_from: this.f['building_count'].value.from,
        building_count_to: this.f['building_count'].value.to
      };
    } else if (this.f['building_count'].value) {
      Input.filters['building_range'] = {
        building_count_from: this.f['building_count'].value.from,
      };
    }
    this.router.navigate(['/search/list']);
    const RequestPayload = new search(Input);
    const payload = RequestPayload.getSearchItem();
    this.searchInputData.emit(this.searchForm.value)
    this.geoService.getZoomLevelbySearch(payload);

    // this.apartmentService.clearemptyObject(payload.filters)
    this.previousValue = this.searchForm.value;
    this.apartmentService.search(payload).subscribe({
      next: (response: any) => {
        this.isCollapsed = true;
      }, error: (error: any) => {
        this.apiService.loadSpinner(false);
        this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
      }
    })
  }

  resetForm() {
    this.searchForm.reset();
  }

  // While Complex Search
  onSearchTermChange(term: any): void {
    const input = (term.target as HTMLInputElement).value;
    this.enableTextSearchOverlay = true
    this.isCollapsed = true;
    if (input != '') this.searchTerm$.next(input);
  }

  loadState() {
    this.GetListAPI(defaultStatesPayload, 'state');
  }

  searchItem(term: any) {
    this.searchForm.patchValue({
      complex_name: term ? term : ''
    });
    this.textLoader = true;
    const payload = {
      ...this.aptFilters,
      filters: {
        // ...this.aptFilters.filters,

        // county: this.f['county'].value ? this.f['county'].value.map((val: any) => val.county) : [],
        city: this.f['city'].value ? this.f['city'].value.map((val: any) => val.city_control) : [],
        zip: this.f['zip'].value ? this.f['zip'].value.map((zip: any) => zip.zip.toString()) : [],
        mso: this.f['mso'].value ? this.f['mso'].value : [],
        complex_name: this.searchForm.get('complex_name')?.value.replace(/ +/g, ' ').trim(),
        verification_status: this.aptFilters.filters['verification_status'] ? this.aptFilters.filters['verification_status'] : [],
        approved_status: this.aptFilters.filters['approved_status'] ? this.aptFilters.filters['approved_status'] : []
      }
    };

    if (this.f['state'].value && this.f['state'].value.length) payload['filters'].state = [this.f['state'].value];
    if (this.f['county'].value && this.f['county'].value.length) payload['filters'].county = this.f['county'].value;

    if (this.f['unit_range'].value && this.f['unit_range'].value.hasOwnProperty('to')) {
      payload.filters['unit_range'] = {
        unit_count_from: this.f['unit_range']?.value.from,
        unit_count_to: this.f['unit_range']?.value.to,
      }
    } else if (this.f['unit_range'].value) {
      payload.filters['unit_range'] = {
        unit_count_from: this.f['unit_range']?.value.from,
      };
    }

    if (this.f['building_count'].value && this.f['building_count'].value.hasOwnProperty('to')) {
      payload.filters['building_range'] = {
        building_count_from: this.f['building_count'].value.from,
        building_count_to: this.f['building_count'].value.to
      };
    } else if (this.f['building_count'].value) {
      payload.filters['building_range'] = {
        building_count_from: this.f['building_count'].value.from,
      };
    }
    return this.apiService.post(API_CONFIG.apartmentAdvancedSearch, payload).pipe(
      retry(2)
    );
  }

  selectComplexName(data: any) {
    this.enableTextSearchOverlay = false
    this.searchForm.patchValue({
      complex_name: data.manual_complex_name ? data.manual_complex_name : data.complex_name ? data.complex_name : '',
      zip: this.searchForm.value.zip
    });
    this.searchResults = [];
    this.doSearch();
  }

  // To get the MSO list
  getMsoList() {
    try {
      this.isloading['mso'] = true;
      this.apiService.get(API_CONFIG.getMsoList).subscribe({
        next: (response: any) => { //If successful response
          if (response && 'mso' in response) {
            this.msoList = response['mso'];
            this.apartmentService.msoList.next(response['mso']);
            this.isloading['mso'] = false;
          }
        }, error: (error: any) => { // If error
          this.apiService.loadSpinner(false);
          this.apiService.alertMessage('danger', ('message' in error) ? error.message : 'Something went wrong, Please try again!');
        }
      })
    } catch (error: any) {
      this.apiService.loadSpinner(false);
      this.apiService.alertMessage('danger', ('message' in error) ? error.message : 'Something went wrong, Please try again!');
    }
  }

  getCounty(value: any): Promise<any> {
    this.apiService.loadSpinner(true);
    return new Promise((resolve, reject) => {
      this.apiService.post(API_CONFIG.getCounty, value).subscribe((data: any) => {
        resolve(data.counties);
        this.apiService.loadSpinner(false);
      });
    });
  }

  getCity(value: any): Promise<any> {
    this.apiService.loadSpinner(true);
    return new Promise((resolve, reject) => {
      this.apiService.post(API_CONFIG.getCities, value).subscribe((data: any) => {
        resolve(data.cities);
        this.apiService.loadSpinner(false);
      });
    });
  }

  getZip(zipPayload: any) {
    this.apiService.loadSpinner(true);
    return new Promise((resolve, reject) => {
      this.apiService.post(API_CONFIG.getZips, zipPayload).subscribe((data: any) => {
        resolve(data.zips)
        this.apiService.loadSpinner(false);
      });
    });
  }

  initializeApartment() {
    // this.cityList = ApartmentModule.loadInitialCity;

    //Search request redirect from Task management
    this.apartmentService.taskFilters$.pipe(take(1)).subscribe((value: any) => {

      this.searchForm.reset();
      setTimeout(() => {
        if (value != null) {
          this.patchStateCityZip(value);

          const payloadTask = {
            filters: value.filters
          }
          payloadTask['filters'].verification_status = this.aptFilters.filters.verification_status
          payloadTask['filters'].approved_status = this.aptFilters.filters.approved_status
          this.apartmentService.changezoomLevel({ zoom: mapSettings.citylevelZoom, value: payloadTask.filters.city, type: 'city' })

          this.apartmentService.search(payloadTask).subscribe((val: any) => { })
          this.isCollapsed = true;
          this.enableOverlay = false
        } else {

          this.apartmentService.changeMarkerPosition.next(this.getChangeRequest);
          if (this.router.url.includes('building')) {
            const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!) : searchDefaultValue
            this.searchInputData.emit(ip.filters)
            this.apartmentService.buildingList$.pipe(take(2)).subscribe((res: any) => {
              if (res.length > 0) {
                this.apartmentService.buildings.next(res);
                this.apartmentService.markerPosition.next(res[0])
              }
            });
            // patching
            const payload = JSON.parse(localStorage.getItem('apartmentPayload')!)
            this.patchStateCityZip(payload)
            this.geoService.getZoomLevelbySearch(ip);
            this.apartmentService.search(ip).subscribe((vl: any) => {
            });
          } else {
            if (JSON.parse(localStorage.getItem('apartmentPayload')!)) {
              // patching
              const payload = JSON.parse(localStorage.getItem('apartmentPayload')!);

              // If no geo selected, then patching Illinois
              if (payload.filters['state'].length == 0) {
                payload.filters['state'] = ['IL'];
              }

              this.patchStateCityZip(payload);
              const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!) : searchDefaultValue
              this.searchInputData.emit(ip.filters)
              this.geoService.getZoomLevelbySearch(ip);
              this.apartmentService.search(ip).subscribe((vl: any) => {
              });
            } else {

              setTimeout(() => {
                this.searchForm.patchValue({
                  state: ApartmentModule.state,
                  // city: ApartmentModule.loadInitialCity
                })
                this.searchForm.get('city')?.enable();
                this.searchForm.get('zip')?.enable();
              }, 10);
              //  load all data in drop down 
              const items = searchDefaultPayload;
              items.state = [ApartmentModule.state]
              items.sort_field = [
                "state",
                "county"
              ]
              delete items.city
              this.GetListAPI(items, 'city');
              delete items.county
              this.GetListAPI(items, 'county');
              items.city = ['IL_WESTMONT']
              this.GetListAPI(items, 'zip');

              const payloads: any = searchDefaultValue;
              payloads.filters['state'] = [ApartmentModule.state]
              // payloads.filters['city'] = [ApartmentModule.loadInitialCity[0].city_control];
              this.apartmentService.search(payloads).subscribe((vl: any) => {
              });
            }
          }

          setTimeout(() => {
            this.previousValue = this.searchForm.value;
            const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!) : searchDefaultValue
            if (ip.filters.building_range) {
              const matchingBuildingCount = BuildingCountRange.find(item => item.from === ip.filters.building_range.building_count_from);
              if (matchingBuildingCount) {
                ip.filters.building_range = { ...matchingBuildingCount };
                this.searchForm.patchValue({
                  building_count: matchingBuildingCount,
                });
              }
            }
            if (ip.filters.unit_range) {
              const matchingUnitRange = unitRangeList.find(item => item.from === ip.filters.unit_range.unit_count_from);
              if (matchingUnitRange) {
                ip.filters.unit_range = { ...matchingUnitRange };
                this.searchForm.patchValue({
                  unit_range: matchingUnitRange
                });
              }
            }
            if (ip.filters.tags) {
              this.searchForm.patchValue({
                tags: this.getTags(ip.filters.tags),
              });
            }
            if (ip.filters.complex_name) {   //used for searced name cannot remove on after refresh
              this.searchForm.patchValue({
                complex_name: ip.filters.complex_name
              });
            }
            this.searchInputData.emit(ip.filters)
          }, 500);
        }
      }, 1000);
    });

  }

  getTags(tags: any) {
    if (tags && tags.length > 0 && typeof tags[0] == 'object') return tags
    return this.tags && this.tags.filter((tag: any) => tags.includes(tag.id));
  }

  patchStateCityZip(payload: any) {

    const state = (payload.filters['state'] != undefined && payload.filters['state'].length > 0) ? payload.filters['state'][0] : [];
    this.searchForm.patchValue({
      state: state,
    });
    const cityPayload = {
      state: payload.filters['state'],
    }
    const countyPayload = {
      state: payload.filters['state'],

    }
    // load county 
    const items = searchDefaultPayload
    items.state = Array.isArray(state) ? state : [state]
    items.sort_field = [
      "state",
      "county"
    ]
    delete items.city
    this.GetListAPI(items, 'city');
    delete items.county
    this.GetListAPI(items, 'county');

    this.getCounty(countyPayload).then((res: any) => {
      if (res) {
        const county = res
          .filter((county: any) => county.county !== '')
          .map((val: any) => {
            val['county_label'] = `${val.county} ${val.city ? `(${val.city})` : ''}`;
            // val['county'] = `${payload.filters['state']}_${val.county}`;
            return val; // Return the modified object
          });

        const filteredArray = county && county.filter((item: any) => payload.filters['county']?.includes(item.county));
        if (filteredArray) {
          this.searchForm.patchValue({
            county: filteredArray
          });
        }
      }
    })
    // this.GetListAPI(this.searchForm.get('state'), 'county');
    this.getCity(cityPayload).then((res: any) => {
      if (res) {
        const city = res
          .filter((city: any) => city.city !== '')
          .map((val: any) => {
            val['city_label'] = `${val.city} (${val.state_full_name})`;
            val['city_control'] = `${payload.filters['state']}_${val.city}`;
            return val; // Return the modified object
          });

        const filteredArray = city && city.filter((item: any) => payload.filters['city'].includes(item.city_control));
        if (filteredArray) {
          this.searchForm.patchValue({
            city: filteredArray
          });
          const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!) : this.searchForm.value
          this.searchInputData.emit(this.searchForm.value);
          if (filteredArray && filteredArray.length == 0) {
            this.searchForm.get('zip')?.disable();
          }
        }

        if (this.searchForm.get('city')?.value && this.searchForm.get('city')?.value.length != 0) {
          this.searchForm.get('zip')?.enable();
          const zipPayload = {
            state: payload.filters['state'],
            city: payload.filters['city']
          }
          this.getZip(zipPayload).then((res: any) => {

            this.zipList = res
            const filteredZipArray = res.filter((item: any) => payload.filters['zip']?.includes(item.zip.toString())).map((item: any) => {
              return { ...item, zip: item.zip.toString() };
            });

            this.searchForm.patchValue({
              zip: filteredZipArray
            });
            this.searchInputData.emit(this.searchForm.value);
          })
          // const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!).filters : searchDefaultValue
        }
      }
    })

  }


  ngOnInit(): void {
   
   /*  this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
     
        if (event.url.includes('building')) {
          // this.aptListFrombuilding = !this.aptListFrombuilding
          // console.log('yes-2');
          this.apartmentService.buildingList$.pipe(take(2)).subscribe((res: any) => {
            // Commented out bacause the list is triggered twice -- 20-JAN-25
            // if(res.length > 0)
            // this.apartmentService.buildings.next(res);
          });
        } else {
          if (event.url.includes('search') && this.aptListFrombuilding) {
            // this.aptListFrombuilding = !this.aptListFrombuilding
            // console.log('yes-1');
            // Commented out bacause the search is triggered twice -- 03-JAN-25

            if(JSON.parse(localStorage.getItem('apartmentPayload')!))
           this.apartmentService.search(JSON.parse(localStorage.getItem('apartmentPayload')!)).subscribe((vl: any) => {
           });
          }
        }
      }
    }); */
    
    this.formInit();
    this.loadState();
    this.initializeApartment();
    this.getMsoList();
    this.searchTerm$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.searchItem(term).pipe(
          catchError((error: any) => {
            this.textLoader = false;
            return of(null);
          })
        ))
      )
      .subscribe((results: any) => {
        if (results) {
          this.searchResults = results.complexes.filter((result: any) => result.complex_name != '' || result.manual_complex_name != '');
        }
        this.textLoader = false;
      });




  }
}
